import React from 'react'
import { useState } from 'react'

function Testinomials({ id, name, image,Length, text }) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    };
    const maxLength = 198
    return (
        <div className=' py-5'>
            <div class="max-w-sm border-[#68AAAD] bg-gradient-to-br from-teal-50 via-teal-100 to-[#68AAAD] rounded-lg  bg-[#B3D4D6]">
                <div class="p-5 h-[15rem]  max-sm:h-[21rem] max-xl:h-[21rem] max-xl:gap-5 max">
                    <a href="#">
                        <h5 class="mb-2 text-2xl font-bold  tracking-tight text-gray-900">{name}</h5>
                    </a>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default Testinomials
