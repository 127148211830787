import React from 'react';
import { useState } from 'react';
import { GiHamburgerMenu, GiCancel } from "react-icons/gi";
import Navbarmini from './Navbarmini';
import logo from '../assets/logo.png';
function Navbar() {
  let Links = [
    { name: "HOME", link: '/express-paiting' },
    { name: "SERVICE", link: '/home-users/services' },
    { name: "ABOUT", link: '/interior/about-us/' },
    { name: "BLOG'S", link: '/interior-design/blogs'},
    { name: "CONTACT", link: "/contact-us" },
  ];

  // State to manage the visibility of the Navbarmini component
  const [showNavbarMini, setShowNavbarMini] = useState(false);
  
  // State to manage the state of the hamburger/cancel icon
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);

  const toggleNavbarMini = () => {
    setShowNavbarMini(!showNavbarMini);
    setIsHamburgerClicked(!isHamburgerClicked);
  };

  return (
    <div className='shadow-md w-full bg top-0 left-0 z-40 fixed'>
      <div className='md:flex md:flex-row items-center justify-between bg-[#68AAAD] py-1 md:px-10 px-7'>
        <div className='font-bold text-2xl cursor-pointer flex justify-between items-center font-[Poppins] text-gray-800'>
          <img className='w-52 max-sm:w-28 border rounded-2xl max-sm:rounded-lg' src={logo} alt="Logo" />
          <div className='md:hidden flex'>
            {/* Toggle the Navbarmini component and change the icon */}
            {isHamburgerClicked ? (
              <GiCancel onClick={toggleNavbarMini} />
            ) : (
              <GiHamburgerMenu onClick={toggleNavbarMini} />
            )}
          </div>
        </div>
        {/* Render Navbarmini component only when showNavbarMini is true */}
        {showNavbarMini}
        <ul className={`md:flex md:items-center font-bold max-sm:-mt-[1.6rem]  md:pb-0 pb-12 absolute md:static bg-[#68AAAD]  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${showNavbarMini ? 'top-20' : 'top-[-490px]'}`}>
          {
            Links.map((link) => (
              <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7 hover:scale-110 transition-all duration-700 ease-in-out'>
                <a href={link.link} className='text-gray-800 duration-500'>{link.name}</a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default Navbar;
