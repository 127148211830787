import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, ValidationError } from '@formspree/react';
import LandPage from './LandPage';
function Contact() {
    const notify = () => toast.success('Message sent!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const [state, handleSubmit] = useForm("mvoergey");
    if (state.succeeded) {
        notify()
    }
    return (
        <div className='w-[85%] m-auto mt-6'>
            <section className="  w-full m-auto py-6 bg-[#B3D4D6] border rounded-xl mb-6">
                <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
                    <div className="py-6 md:py-0 md:px-6 text-black m-auto">
                        <h1 className="max-sm:text-[2rem] text-[2rem]  font-bold">Get in touch</h1>
                        <p className="pt-2 pb-4 text-[1.3rem] max-sm:text-[1rem] font-semibold">Fill in the form to start a conversation</p>
                        <div className="space-y-4">
                            <p className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                                </svg>
                                <span className='font-semibold text-[1.3rem] max-sm:text-[0.9rem]'>New Delhi, Tilak Nagar</span>
                            </p>
                            <p className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                                </svg>
                                <span className='font-semibold text-[1.3rem]'>8447122759</span>
                            </p>
                            <p className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                                <span className='font-semibold text-[1.3rem] max-sm:text-[0.8rem] max-lg:text-[0.9rem]'>expresshetram@gmail.com</span>
                            </p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="flex flex-col py-6 space-y-6 md:py-0 md:px-6">
                        <label className="block">
                            <span className="mb-1">Full name</span>
                            <input type="text" name="user_name" placeholder="Full Name" className="block w-full rounded-md p-1 shadow-sm focus:ring  dark:bg-gray-100" />
                        </label>
                        <label className="block">
                            <span className="mb-1">Email address</span>
                            <input type="email" name="user_email" placeholder="example@gmail.com" className="block border w-full rounded-md p-1 shadow-sm focus:ring focus:ri focus:ri dark:bg-gray-100" />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                        </label>

                        <label className="block">
                            <span className="mb-1">Phone Number</span>
                            <input type="number" name="number" placeholder="+91(94747xxxxx)" className="block p-1 w-full rounded-md shadow-sm focus:ring focus:ri focus:ri dark:bg-gray-100" />
                            <ValidationError
                                prefix="number"
                                field="number"
                                errors={state.errors}
                            />
                        </label>

                        <label className="block">
                            <span className="mb-1">Address</span>
                            <textarea name="message" rows="3" className="block w-full rounded-md focus:ring focus:ri focus:ri dark:bg-gray-100"></textarea>
                        </label>
                        <button type="submit" disabled={state.submitting} className="self-center px-8 py-3 text-lg rounded dark:bg-[#1995AD]">Submit</button>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                    </form>
                </div>
            </section>

        </div>
    )
}

export default Contact
