import React from 'react'

function PageNotFound() {
    return (
        <div>
            <div className=' h-[44rem]   flex flex-col items-center justify-center align-middle text-[3rem] max-sm:text-[2rem] font-bold'>
                <p>404! Page Not found</p>
            <img src='/Images/404-error (1).png' alt='not found' width={90} />
            </div>
            
        </div>
    )
}

export default PageNotFound