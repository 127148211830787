import React, { useState } from 'react';
import Color from './Color';

function ColorsPalette(props) {
    const { colors } = props;
    const [visibleColors, setVisibleColors] = useState(20); // Initially show 20 colors

    const handleLoadMore = () => {
        setVisibleColors(prevCount => prevCount + 20); // Incrementally add more 20 colors
    };

    return (
        <div className='text-black bg-[#E5F0F1] '>
            <h1 className='text-center text-[3rem] font-bold font-serif mb-5'>Colour Palette</h1>
            <div className='flex flex-wrap flex-row gap-5 justify-center'>
                {colors.slice(0, visibleColors).map((color, index) => (
                    <div key={index}>
                        <Color color={color}/>
                    </div>
                ))}
            </div>
            {visibleColors < colors.length && (
                <button className="btn-load-more text-center p-3 max-sm:w-[10rem] text-[100%] mt-[3rem] border-1 shadow-lg shadow-[rgb(65,122,117)] rounded-lg bg-[#68AAAD] hover:bg-[#9AC6C8] ml-[45%] max-sm:ml-[25%]" onClick={handleLoadMore}>Load More</button>
            )}
        </div>
    );
}

export default ColorsPalette;
