import React, { useState } from 'react';

function Choose({ id, name, image,Length,text}) {
  const [isCollapse, setIsCollapse] = useState(true);

  const toggleCollapsed = () => {
    setIsCollapse(!isCollapse);
  };
  const maxLength = 198
  return (
    <div>
      <div className="max-w-sm shadow-[0_35px_60px_-15px_rgb(104, 170, 173, 1)] bg-gradient-to-br from-[#CCE2E3] via-[#9AC6C8] to-[#68AAAD] rounded-lg bg-[#B3D4D6]">
        <a href="#">
          <img className="rounded-t-lg" src={image} alt="" />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className="mb-2 text-2xl max-sm:text-xl font-bold tracking-tight text-black">{name}</h5>
          </a>
          {isCollapse ? (
            <div className=''>
              {`${text.slice(0, maxLength)}...`}
              <br />
              <br />
              {text.length > maxLength && (
                <button onClick={toggleCollapsed} href="#" className="w-[8rem] inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black bg-[rgb(229,106,78)] rounded-lg hover:bg-[hsl(11,52%,46%)] focus:ring-4 focus:outline-none divide-sky-700 ">
                  Read more
                </button>
              )}
            </div>
          ) : (
            <>
              {text}
              <br />
              <br />
              <button onClick={toggleCollapsed} href="#" className="w-[8rem] inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black bg-[rgb(229,106,78)] rounded-lg hover:bg-[hsl(11,52%,46%)] focus:ring-4 focus:outline-none divide-sky-700 ">
                Show less..
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Choose;
