import React from 'react'
import { GiCancel } from "react-icons/gi";

function Navbarmini() {
    let Links = [
        { name: "HOME", link: "/" },
        { name: "SERVICE", link: "/services" },
        { name: "ABOUT", link: "/about" },
        { name: "BLOG'S", link: "/blogs" },
        { name: "CONTACT", link: "/contact" },
    ];
    return (
        <div className=' absolute w-[90%]  bg-[#A1D6E2] flex p-10 flex-row  justify-around z-50'>
            <div>
            <ul className=" text-lg font-semibold text-center flex flex-col gap-10 m-auto">
                {
                    Links.map((link) => (
                        <li key={link.name} className='text-x font-bold hover:scale-110 transition-all duration-700'>
                            <a href={link.link} className='hover:scale-110 transition-all duration-700'>{link.name}</a>
                        </li>
                    ))
                }
            </ul>
            </div>
        </div>
    )
}

export default Navbarmini