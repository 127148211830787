import React from 'react';
import Navbar from './Components/Navbar';
import Services from './Components/Services'
import Customer from './Components/Customer';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import About from './Components/About';
import ColorsPalette from './Components/ColorsPalette';
import Why from './Components/why';
import LandPage from './Components/LandPage';
import PageNotFound from './Components/PageNotFound';
import data from './data2';
import './App.css'
import reviews from './data'
import choice from './data3'
import colors from './colors'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
function App() {
  return (
    <BrowserRouter >
      <div className='bg-[#E5F0F1] '>
        <Navbar />
        <Routes>
        <Route path='/' element={
            <div className='max-sm:-mt-6'>
              <LandPage />
              <About />
              <Services data={data} />
              <Why choice={choice} />
              <Customer reviews={reviews} />
              <Contact />
            </div>
          } />
          <Route path='/express-paiting' element={
            <div className='max-sm:-mt-6'>
              <LandPage />
              <About />
              <Services data={data} />
              <Why choice={choice} />
              <Customer reviews={reviews} />
              <Contact />
            </div>
          } />
          <Route path = '*' element = {<div>{<PageNotFound/>}</div>}/>
          <Route path='/home-users/services' element={<div className='mt-20 max-sm:mt-[2rem] max-lg:mt-[3rem]'><Services data={data} /></div>} />
          <Route path='/interior-design/blogs' element={<div className='mt-[6rem] max-sm:-mt-[0.5rem] max-lg:mt-[4.7rem]'><Why choice={choice} /></div>} />
          <Route path='/contact-us' element={<div className='mt-[6rem] max-sm:mt-[2rem] max-lg:mt-[2rem] p-8'><Contact /></div>} />
          <Route path='/interior/about-us/' element={<div className='mt-[6rem] max-sm:mt-[2rem] p-8 max-lg:mt-[4rem]'><About /></div>}/>
          <Route path = '/utility-design/colour-catalogue' element = {<div className='mt-[6rem] max-sm:mt-[2rem] max-lg:mt-[2rem] p-8'><ColorsPalette colors = {colors}/></div>}/>
        </Routes>
        <Footer />
      </div>  
    </BrowserRouter>

  );





}

export default App
