
const choice = [
    {
      id: 1,
      name: "Color Consulation",
      image: "/Images/cutomer6.jpg",
      maxLength:200,
      text: "we offer a comprehensive color consultation to our valued customers. Understanding that selecting the right colors can transform a space, our expert consultants work closely with clients to explore various palettes, shades, and combinations that align with their preferences and vision",
    },
    {
      id: 2,
      name: "Safety Protocol",
      image: "/Images/cutomer6.jpg",
      maxLength:183,
      text: "Safety is our top priority. We adhere to strict safety measures and protocols, conduct thorough risk assessments, and provide comprehensive safety training to ensure a secure working environment for our team and clients, instilling confidence and safeguarding our workforce",
    },
    {
      id: 3,
      name: "Mechanized Tools",
      image: "/Images/cutomer6.jpg",
      maxLength:198,
      text: "Mechanized tools revolutionize our approach, enhancing efficiency and precision. We use state-of-the-art machinery and equipment to streamline processes and deliver superior results. By integrating mechanized tools into our workflow, we optimize productivity and ensure quality across all our endeavors.",
    },
    {
      id: 4,
      name: "Trained Professionals",
      image: "/Images/cutomer6.jpg",
      maxLength:204,
      text: "Our team comprises extensively trained professionals who bring expertise, skill, and dedication to every project. With years of experience in the industry, our trained professionals possess the knowledge and proficiency necessary to tackle diverse challenges and deliver exceptional results.",
    },
    {
      id: 5,
      name: "Furniture & floor Cover",
      image: "/Images/cutomer6.jpg",
      maxLength:176,
      text: "Our expert team offers comprehensive floor and furniture covering services for a hassle-free whitewashing experience. We prioritize preserving the aesthetics and functionality of your home. Rest assured that every corner of your space remains pristine throughout the process.",
    },
    {
      id: 5,
      name: "Timely Completion",
      image: "/Images/cutomer6.jpg",
      maxLength:195,
      text: "Timely completion is a cornerstone of our service philosophy. We recognize the significance of meeting deadlines, whether they are set by you, our valued client, or dictated by external factors. Our commitment to timely completion is underpinned by several key principles",
    },
  ];
  
  export default choice;
  