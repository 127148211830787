import React from 'react'

function Card({id,title,image,decscription}) {
    
    return (
        <div className=' border-1 shadow-lg shadow-[#68AAAD] rounded-lg'>
            <div class="max-w-sm rounded-lg bg-[#B3D4D6] bg-gradient-to-br from-[#CCE2E3] via-[#9AC6C8] to-[#68AAAD]">
                <a href="#">
                    <img class="rounded-t-lg" src={image} alt="" />
                </a>
                <div class="p-5">
                    <a href="#">
                        <h5 class="mb-2 text-2xl text-center font-bold tracking-tight text-gray-900 ">{title}</h5>
                    </a>
                    <p class="mb-3 font-normal text-black ">{decscription}</p>
                </div>
            </div>



        </div>
    )
}

export default Card
