import React from 'react';

function Color(props) {
  const { color, name, code } = props.color;
  return (
    <div className=" w-52 h-52 max-sm:w-32 max-sm:h-32 max-md:w-28 max-md:h-28 max-lg:w-36 max-lg:h-36  border-1 shadow-lg shadow-[#68AAAD] rounded-lg" style={{ backgroundColor: color }}>
      <div className=' font-medium text-center'>
        <p >{name}</p>
        <p>{code}</p>
      </div>
    </div>
  );
}

export default Color;
