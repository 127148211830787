
const reviews = [
  {
    id: 1,
    name: "Pranay Gupta",
    image: "/Images/cutomer1.webp",
    maxLength: 192,
    text: "The team transformed our home with their expert painting skills, and the results exceeded our expectations. Not only did they complete the project on time, but they also went above and beyond to address any concerns and make sure every detail was perfect.",
  },
  {
    id: 2,
    name: "Abir Pal",
    image: "/Images/cutomer2.webp",
    maxLength: 188,
    text: "We recently hired this painting company to refresh the interior of our office space, and we couldn't be happier with the outcome. The entire team was a pleasure to work with, demonstrating exceptional professionalism and craftsmanship throughout the project.",
  },
  {
    id: 3,
    name: "Soumya Banerjee",
    image: "/Images/cutomer3.webp",
    maxLength: 189,
    text: "They worked efficiently to minimize disruption to our business operations and delivered flawless results within the agreed-upon timeframe. We've received numerous compliments from tenants, all thanks to the exceptional workmanship of this team.",
  },
  {
    id: 4,
    name: "Saikat Mukherjee",
    image: "/Images/cutomer4.avif",
    maxLength: 188,
    text: "▪️Good quality paint using in work like_ Royal paint, plastic paint, distemper. ▪️Furniture polish, PU polish, melamine glossy and matte finish, lekar polish. ▪️Dico spray paint, designer trecher wall. ▪️Hanging wallpaper, 3D Indian important. ▪️All house maintaining work and repairing.",
  },
  {
    id: 5,
    name: "Aritra Biswas",
    image: "/Images/cutomer5.avif",
    maxLength: 190,
    text: "▪️Good quality paint using in work like_ Royal paint, plastic paint, distemper. ▪️Furniture polish, PU polish, melamine glossy and matte finish, lekar polish. ▪️Dico spray paint, designer trecher wall. ▪️Hanging wallpaper, 3D Indian important. ▪️All house maintaining work and repairing.",
  },
  {
    id: 5,
    name: "Vipin Kumar",
    image: "/Images/cutomer6.avif",
    maxLength: 188,
    text: "▪️Good quality paint using in work like_ Royal paint, plastic paint, distemper. ▪️Furniture polish, PU polish, melamine glossy and matte finish, lekar polish. ▪️Dico spray paint, designer trecher wall. ▪️Hanging wallpaper, 3D Indian important. ▪️All house maintaining work and repairing.",
  },
];

export default reviews;
