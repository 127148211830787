import React from 'react'
import './landing.css'

function LandPage() {
  const phoneNumber = '8447122759';
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div className='w-full max-sm:mt-20 bg-gradient-to-r from-[#E5F0F1] to-[#9AC6C8]'>
      <div className='w-[95%] m-auto  mb-6 '>
        <div className=" w-full flex flex-row max-lg:flex-col transition-all delay-500 ">
          <img className='mt-24 max-sm:hidden  max-lg:hidden max-xl:w-[40%] max-2xl:h-[28rem]' src="/Images/image-2.png" />
          <div className="cursor-default sm:pl-20 text-[4rem] max-md:text-4xl max-xl:text-[1.8rem] max-sm:text-3xl font-serif font-bold  pt-40 p-9 max-md:pt-10 max-sm:pt-10">

            <div>
              <p className=" text-center mb-6 animate-fade-up animate-fadeUp hover:animate-fadeUp"><span className='text-[7rem] max-md:text-6xl max-sm:text-4xl text-[#E55733] leading-10 max-xl:text-[5rem]'>T</span>ransform your Living Space Into a <span className='text-[#E15933]'>Dream</span> Home</p>

              <div className='text-lg w-[90%] max-sm:text-sm text-center'>
                < p>We offer a wide range of services aimed at improving your living spaces.
                  exterior Our services include interior painting, furniture polishing, wall texturing,exterior painting, and more...</p>
              </div>
              <div className='text-[1rem] flex flex-wrap  justify-center gap-10 mt-5'>
                <button type="button" onClick={handleCallClick} className=" w-[7rem] p-3 border-1 shadow-lg rounded-lg  bg-[#E15933] hover:bg-[#E48C74]">
                  Call us
                </button>
                <a href='/contact-us'>
                  <button type="button" className="w-[7rem] p-3 border-1 shadow-lg rounded-lg  bg-[#E15933] hover:bg-[#E48C74]">
                    Contact us
                  </button>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandPage


// bg-gradient-to-b from-[#69c4b7] via-[#69c4b7] to-[#E5F0F1]