import React from 'react'
import Testinomials from './Testinomials'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Customer(props) {
    // #1995AD, #A1D6E2, #F1F1F2  [rgb(229,106,78)]
    const reviews = props.reviews
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='w-[95%] m-auto  bg-[#E5F0F1]'>
            <div className='w-full h-full mt-10 p-8 mb-10 max-lg:mb-0'>
                <h1 className='text-5xl text-black font-serif font-bold text-center max-sm:text-4xl'>Customer Testimonials</h1>
                <div className='w-[75%] max-sm:w-full m-auto text-black mt-5 max-lg:mt-0 mb-8 max-lg:mb-0 text-lg text-center font-medium font-serif'>
                    <p><span className='max-sm:hidden max-lg:hidden'>Seeking a dependable, secure solution for your home's painting needs? </span><br />
                        <span className='max-sm:hidden max-lg:hidden'>Your search ends here! Discover Express Painting Service,
                            your one-stop destination for a wide array of services. </span>
                        <span><br />
                            From interior and exterior home painting to deck painting and staining, wood finishing,
                            and faux finishing, we offer a comprehensive range to bring your vision to life,
                            all delivered with utmost reliability and safety
                        </span>
                    </p>
                </div>
                <div className='w-11/12 mx-auto bg-[#E5F0F1] gap-5 max-xl:gap-4 border rounded-md'>
                    <Slider {...settings}>
                        <div><Testinomials {...reviews[0]} /></div>
                        <div><Testinomials {...reviews[1]} /></div>
                        <div><Testinomials {...reviews[2]} /></div>
                        <div><Testinomials {...reviews[3]} /></div>
                        <div><Testinomials {...reviews[5]} /></div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Customer
