import React from 'react'
import Card from './Card'
import Slider from "react-slick";


function Services(props) {
  const data = props.data;
  
  return (
    <section className='w-[95%] m-auto  bg-[#E5F0F1]'>
      <div className='w-full h-full p-4 '>

        <h3 className='text-5xl font-serif font-extrabold flex justify-center items-center p-5 mb-5 text-center'><p>Our's  Services</p></h3>
        <div className='flex flex-wrap justify-center align-middle gap-5 '>
          <Card {...data[0]} />
          <Card {...data[1]} />
          <Card {...data[2]} />
          <Card {...data[3]} />
          <Card {...data[4]} />
          <Card {...data[0]} />
        </div>
      </div>
    </section >

  )
}

export default Services

