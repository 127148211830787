import React from 'react'
// Get the width and height of the current window
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;

// Output the dimensions
console.log("Window Width: " + windowWidth + "px");
console.log("Window Height: " + windowHeight + "px");


function About() {
  return (
    <div className='w-[80%] m-auto bg-[#CCE2E3] mb-10 flex sm:flex-row max-sm:flex-col border-1 shadow-lg shadow-[#68AAAD] rounded-lg border-solid  border-[#68AAAD]'>
      <div className='w-[50%] max-sm:w-full sm:m-auto ' >
        <div className='text-[10rem] text-black max-sm:text-2xl max-lg:text-4xl font-serif font-extrabold animate-fadeUp flex justify-center'>
          <h3 className='text-[3rem]  max-md:text-[1.4rem]  max-lg:text-[1.8rem] max-xl:text-[1.8rem] font-serif font-extrabold m-2 text-center'>Our <span className='t text-orange-500'>Line </span> of Work</h3>
        </div>
        <div className='text-center max-lg:text-sm font-serif sm:ml-10 max-sm:p-2  text-xl items-center text-wrap max-lg:mt-3 max-2xl:text-[1rem] font-medium'>
          <p>We offer a wide range of services aimed at improving your living spaces.
            Our services include interior painting, furniture polishing, wall texturing,
            exterior painting, and more. 
            </p>
            <p className='max-lg:hidden max-xl:hidden'>
            With over two decades of experience in delivering
            high-quality craftsmanship, our team is well-trained and strictly adheres to stringent
            security protocols. We prioritize the safety of our clients during every whitewash project.
            Our work is transformative, and we strongly believe in its power.
            </p>
            <a href='/utility-design/colour-catalogue'><button className='mt-4 p-3 mb-5 border-1 shadow-lg rounded-lg  bg-orange-500 hover:bg-[#E48C74]'>Colors Consultation</button></a>
        </div>
      </div>
      <div className='max-sm:hidden max-lg:w-[60%] max-xl:w-[50%] max-2xl:w-[50%]  animate-fadeSide transition-all delay-500'>
        <img src='/Images/image-3.png' />
      </div>
    </div> 
  )
}

export default About