
const servies = [
    {
      id: 1,
      title: "Interior Painting",
      image: "/Images/1.jpg",
      decscription: "We provide expert interior painting services that turn ordinary walls into works of art. Our skilled team uses high-quality materials and the latest techniques to deliver exceptional results.",
    },
    {
      id: 2,
      title: "Enterior Painting",
      image: "/Images/2.jpg",
      decscription: "Transforming exteriors into stunning works of art is our specialty. We use only high-quality materials and advanced techniques to ensure unmatched durability and long-lasting beauty.",
    },
    {
      id: 3,
      title: "WaterProofing",
      image: "/Images/3.jpg",
      decscription: "Experience unparalleled waterproofing solutions tailored to your specific needs. Our commitment to superior craftsmanship and customer satisfaction sets us apart.",
    },
    {
      id: 4,
      title: "Wall Textures",
      image: "/Images/5.jpg",
      decscription: "Our team is dedicated to providing the best outcome,combining creativity with meticulous attention to detail.Using only premium materials and adhering to strict safety protocols",
    },
    {
      id: 5,
      title: "Furniture",
      image: "/Images/4.jpg",
      decscription: "Our furniture is made of high-quality wood and is known for its durability. We prioritize quality to create pieces that are both elegant and long-lasting.",
    },
      {
      id: 6,
      title: "Furniture Polish",
      image: "/Images/1.jpg",
      decscription: "▪️Good quality paint using in work like_ Royal paint, plastic paint, distemper. ▪️Furniture polish, PU polish, melamine glossy and matte finish, lekar polish. ▪️Dico spray paint, designer trecher wall. ▪️Hanging wallpaper, 3D Indian important. ▪️All house maintaining work and repairing.",
    },
  ];
  
  export default servies;
  