import React from 'react'
import Choose from './choose'
import Slider from "react-slick";

function why(props) {
  const choice = props.choice
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='w-[95%] m-auto  bg-[#E5F0F1] mb-10'>
      <div className='w-full mt-16 flex flex-col justify-center '>
        <div className='text-center mb-10 '>
          <span className='text-5xl font-extrabold font-serif'>Why <span className="text-[rgb(229,106,78)]">Choose</span> Us</span>
        </div>
        <div className='w-[80%] h-full text-center font-sarif m-auto text-xl mb-5 font-normal '>
          <p className='max-sm:hidden'>We're a highly experienced team of professionals who specialize in transforming living spaces with our interior and exterior house painting services.
            Our well-trained employees stay up-to-date with the latest industry advancements,
            and our commitment to excellence has made us one of the top dealers in the market.
          </p>
          <p className='text-2xl mt-10 font-medium'>Choose us for a result that's both aesthetically stunning and enduring.</p>
          <hr className='h-2px border bg-[#1995AD]' />
        </div>
        <div className='w-[85%] mx-auto gap-4 max-lg:gap-3 max-sm:gap-3 border rounded-xl'>
          <Slider {...settings}>
            <div>
              <Choose {...choice[0]} />
            </div>
            <div>
              <Choose {...choice[1]} />
            </div>
            <div>
              <Choose {...choice[2]} />
            </div>
            <div>
              <Choose {...choice[3]} />
            </div>
            <div>
              <Choose {...choice[4]} />
            </div>
            <div>
              <Choose {...choice[5]} />
            </div>
          </Slider>
        </div>

      </div>
    </div>
  )
}

export default why
