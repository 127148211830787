import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'animate.css/animate.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter } from "react-router-dom";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
        <App/>
);

//#68AAAD
// #81B8BA
// #9AC6C8
// #B3D4D6
// #CCE2E3
// #E5F0F1